import React, { useCallback, useEffect, useState } from 'react'
import bepassCopyright from './images/bepass_copyright_dark.png'
import clientLogo from './images/logo-arena-mrv.png'
import batfair from './images/logo-arena-mrv.png'
import './App.sass'

import { BASE } from './basedata/constants'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'
import SuccessHandler from './Biometric/StatesComponents/SuccessHandler'
import { Card } from 'react-bootstrap'

function App() {
  const [userState, setuserState] = useState<string>('')

  const [userToken, setuserToken] = useState<any>('')

  const [isLoading, setIsLoading] = useState(true)

  const handleResponse = useCallback((message: any) => {
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  const verifyBiometry = (token: any) => {
    
    if (token.substring(0, 4) === "zay6") {
      window.location.href = `https://bgs.bepass.com.br/?token=${token.split('zay6')[1]}`
    }

    else if (token.substring(0, 4) === "xip7") {
      window.location.href = `https://maracana.bepass.com.br/?token=${token.split('xip7')[1]}`
    }

    else if (token.substring(0, 4) === "xip8") {
      window.location.href = `https://maracana.bepass.com.br/?token=${token.split('xip8')[1]}`
    }
    else if (token.substring(0, 4) === "xpt9") {
      window.location.href = `https://penaareia.bepass.com.br/?token=${token.split('xpt9')[1]}`
    }
    else if (token.substring(0, 4) === "xds1") {
      window.location.href = `https://deusamba.bepass.com.br/?token=${token.split('xds1')[1]}`
    }else if (token.substring(0, 4) === "xp0t") {
      window.location.href = `https://honda.bepass.com.br/?token=${token.split('xp0t')[1]}`
    }else if (token.substring(0, 4) === "pdfp") {
      window.location.href = `https://praiadoforte.bepass.com.br/?token=${token.split('pdfp')[1]}`
    }else{
      // setIsLoading(false)
      window.location.href = `https://arenamrv.bepass.com.br/?token=${token}`

    }
    
    

    fetch(`${BASE.api.base_url}${BASE.api.validate_face}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token,
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        "token": token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {



        if (response.biometry) {

          if (response.callbackUrl) {

            const redirect = window.sessionStorage.setItem('callbackurl', `${response.callbackUrl}`)

          }
          setuserState('facesuccess')


        } else {
          setuserState('faceinformation')
        }


      })
      .catch((error) => {
        return false
      })
  }


  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    const generatedToken = window.sessionStorage.getItem('token')
    if (params.get('token')) {
      const currentToken = params.get('token'),
        currentCompany = params.get('orc')
      setuserToken(params.get('token'))
      window.sessionStorage.setItem('token', currentToken + '')
      window.sessionStorage.setItem('originCompany', currentCompany + '')

      return verifyBiometry(currentToken)
      // setuserState('faceinformation')
    }

    else if (generatedToken) {
      const currentToken = generatedToken
      setuserToken(generatedToken)
      return verifyBiometry(generatedToken)
      // setuserState('faceinformation')
    } else {


      if (window.location.pathname.length > 2) {

        const urlparam = window.location.pathname.replace('/', '')
        const uuid = `${urlparam.substring(0, 8)}-${urlparam.substring(8, 12)}-${urlparam.substring(12, 16)}-${urlparam.substring(16, 20)}-${urlparam.substring(20)}`;

        fetch(`${BASE.api.base_url}${BASE.api.user_create}/${uuid}`, {
          method: 'GET',
          headers: {
            'x-api-key': BASE.api.api_key
          }
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.token) {
              const currentToken = response.token,
                currentCompany = response.originCompanyId
              setuserToken(response.token)
              window.sessionStorage.setItem('token', currentToken + '')
              window.sessionStorage.setItem('originCompany', currentCompany + '')

              return verifyBiometry(currentToken)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        window.location.href = `https://ingresse.bepass.com.br`

        setIsLoading(false)
        setuserState('signup')
      }

    }
  }, [])


  if(isLoading){
    return (<></>)
  }

else{
  return (
    <p>.</p>
  )
}
}

export default App
